var timeoutID,logOffURL,configuredTimeOut,overLayTimeOutInSec,timeinterval;
var isOverLayOpen=false,
cardType = null,
journalKey = null,
cardNumber= null,
paymentMode= null,
PX_COUNT = 0;

function showResetSessionOverlay(msgFlag){
	isOverLayOpen=true;
	if(msgFlag==="auto"){
		$("#resetSessionConfirmation").modal();
		$("#autoResetSessionMsg").removeClass("hide");
		var counterDisplayLoc= document.getElementById("overLayTimerCountdown");
		var currentTime = Date.parse(new Date());
		var endTime = new Date(currentTime + (overLayTimeOutInSec*1000));
		initializeOverLayCounter(counterDisplayLoc, endTime);
	} else if (msgFlag==="manual"){
		$("#resetSessionConfirmation").modal();
		$("#manualResetSessionMsg").removeClass("hide");
	}
}
function getTimeRemaining(endtime){
	  var t = Date.parse(endtime) - Date.parse(new Date());
	  var seconds = Math.floor( (t/1000) % 60 );
	  return {
	    'total': t,
	    'seconds': seconds
	  };
}
function initializeOverLayCounter(id, endtime){
	  var clock = document.getElementById(id);
	  function updateClock(){
	    var t = getTimeRemaining(endtime);
	    displayOverLayCountDown(t.seconds,id);
	    if(t.total<=0){
	      clearInterval(timeinterval);
	      if(isOverLayOpen){
	    	  if(!TSCUtils.isNullObj(TSCUtils.getCookie("kioskPayInt")) && TSCUtils.isNullObj(homeURL)){
	    		   var logOffURLArray = logOffURL.split("&URL=");
	    		   var kioskLogOffURL = logOffURLArray[0] + "&URL=" + homeURL;
	    		   logout(kioskLogOffURL);
	    	  }else if(!TSCUtils.isNullObj(TSCUtils.getCookie("stockyardHomePage")) && TSCUtils.getCookie("stockyardHomePage") !=='' ){
	    		   var logOffURLArray = logOffURL.split("&URL=");
	    		   var kioskLogOffURL = logOffURLArray[0] + "&URL=" + TSCUtils.getCookie("stockyardHomePage");
	    		   logout(kioskLogOffURL);
	    	  }else{
	    		  logout(logOffURL);
	    	  }
	      }
	    }
	  }
	  updateClock();
	  timeinterval = setInterval(updateClock,1000);
}
function cancelResetSessionOverlay(){
	isOverLayOpen=false;
	$("#resetSessionConfirmation").modal("hide");
	clearInterval(timeinterval);
}
function displayOverLayCountDown(dispSecond, displayLocation){
	displayLocation.innerHTML = dispSecond;
}
function loadSpecialOrder(inConfiguredGuestTimeOut,inConfiguredRegTimeOut,inUrl,inOverLayTimeOut){
	logOffURL=inUrl;
	configuredTimeOut=inConfiguredGuestTimeOut;
	overLayTimeOutInSec = inOverLayTimeOut;
	//Guest User inactivity
	if (!TSCUtils.isNullObj(TSCUtils.getCookie("inStoreOrder")) && TSCUtils.isNullObj(TSCUtils.getCookie("userName"))){
		initiateInactivityListener();
	}
	//Registered user inactivity
	else if (!TSCUtils.isNullObj(TSCUtils.getCookie("inStoreOrder")) && !TSCUtils.isNullObj(TSCUtils.getCookie("userName"))){
		configuredTimeOut=inConfiguredRegTimeOut;
		initiateInactivityListener();
	}
	//User inactivity for Kiosk Simple.
	else if (! TSCUtils.isNullObj(TSCUtils.getCookie("kioskPayInt"))){
		initiateInactivityListener();
	}
}
function getCartTotalValue(){
	if(! TSCUtils.isNullObj(TSCUtils.getCookie("WC_CartOrderId_10151")) && ! TSCUtils.isNullObj(TSCUtils.getCookie("WC_CartTotal_"+TSCUtils.getCookie("WC_CartOrderId_10151")))) {
		return true;
	}
	return false;
}

function initiateInactivityListener() {	
	console.debug("INSTORE INACTIVITY LISTENER INITIALIZATION");
    this.addEventListener("mousedown", resetTimer, false);
	this.addEventListener("mouseover", resetTimer, false);
	this.addEventListener("click", resetTimer, false);
    this.addEventListener("keypress", resetTimer, false);
    this.addEventListener("DOMMouseScroll", resetTimer, false);
    this.addEventListener("mousewheel", resetTimer, false);
    this.addEventListener("touchmove", resetTimer, false);
    this.addEventListener("MSPointerMove", resetTimer, false);
    startTimer();
} 
function startTimer() {
    // wait <configuredTimeOut> seconds before calling goInactive
    timeoutID = window.setTimeout(goInactive, configuredTimeOut);
} 
function resetTimer(e) {
    window.clearTimeout(timeoutID); 
    goActive();
} 
function goInactive() {
    if(isOverLayOpen){
    	cancelResetSessionOverlay();	
    	showResetSessionOverlay("auto");
    } else{
    	showResetSessionOverlay("auto");
    }
} 
function goActive() {             
    startTimer();
}

function invokeStockyardLocalization(){
	var methodName= "invokeStockyardLocalization()";
	console.info("Entering Method "  + methodName);	
	
	console.info("Inside invokeStockyardLocalization() :: kioskDeviceId : " + kioskDeviceId + " kioskStoreNumber : " + kioskStoreNumber);
	
	if(TSCUtils.isNullObj(TSCUtils.getCookie("inStoreOrder")) && typeof kioskJavaScriptInject != "undefined"  
                              && typeof kioskStoreNumber != "undefined" &&  typeof kioskDeviceId != "undefined" && kioskJavaScriptInject){
			
		   console.info("Stockyard cookie already exists for Store# :: " + TSCUtils.getCookie("inStoreOrder"));
		   storeNumber = kioskStoreNumber;
		   var endTime = getExpirationTime(3600 * 1000 * 24 * 365 * 3);
		   TSCUtils.setCookie("inStoreOrder",kioskStoreNumber, {"expires":endTime, path: '/'} );
		   TSCUtils.setCookie("kioskPayInt",kioskDeviceId + "-" + kioskDepartment, {"expires":endTime, path: '/'} );
		   // Adding new cookie to track Digital Kiosks
		    if( typeof digitalKiosk != "undefined" ){
			   TSCUtils.setCookie("digitalKiosk","true", {"expires":endTime, path: '/'} );
		    }
			var lpStoreNumValue = TSCUtils.getCookie("lpStoreNum");
			if( lpStoreNumValue != storeNumber)
				TSCUtils.removeCookie("lpStoreNum",{path: '/'});
		   
		   console.info("Before invoking Stockyard Localization for Store :: " + TSCUtils.getCookie("inStoreOrder"));			
		   var params = [];
		   params.storeNumber = storeNumber;
		   wcService.invoke("AjaxTSCGetInStoreDetails", params);
			
		   setTimeout(function(){
				zipcode_widjet_Id.modal('hide');
				$("#our_overlay").hide();
				$("#menuUnderlayWrapper").addClass("hide");
				scrollTop();
				},3000);		
			console.info("After invoking Stockyard Localization for Store :: " + TSCUtils.getCookie("inStoreOrder"));
	}else{
		console.info("Stockyard cookie already exists for Store# :: " + TSCUtils.getCookie("inStoreOrder"));
	}
	console.info("Exiting Method "  + methodName);
}