FooterJs = {
		footerControllerServiceInvoked:false,
		declareFragmentFooter_controller : function(){
			var containerId = "fragmentFooterDisplay";
			var contextId = "FooterDisplay_Context";
			var myWidgetObj = $("#" + containerId);
			if (!wcRenderContext.checkIdDefined(contextId)) {
				wcRenderContext.declare(contextId, [], {'isAjaxFlow':'true', 'ext':'ext'});
			}
			wcRenderContext.addRefreshAreaId(contextId, containerId);
			var myRCProperties = wcRenderContext.getRenderContextProperties(contextId);
			myWidgetObj.refreshWidget({
				renderContextChangedHandler: function() {
					myWidgetObj.refreshWidget("refresh", myRCProperties);
				},
				postRefreshHandler: function(widget){
					$(window).off('scroll.Footer');
					var expFragFooterLoaded = $("#footerExperienceFragmentLoadedHidden").val();
					if(expFragFooterLoaded != 'true'){
						FooterJs.bindFooterFlyouts();
						Widget.bindEmailWidget();
						if ( TSCUtils.getCookie("inStoreOrder") !== "" ){
							$("#social_connect_container_id").hide();
							$("#ResetSessionBtn").show();
						}
						if($("form[name=cityStateForm]").length){
							FooterJs.bindFooterStoreLocator();
						}
						var isGuest = !(TSCUtils.getCookie('isRegisteredUser') === 'Y');
						if (!isGuest) {
							$("#ncFooterRegisteredAbout").removeClass("hide");
							$("#ncFooterRegisteredMob").removeClass("hide");
						}else{
							$("#ncFooterGuestAbout").removeClass("hide");
							$("#ncFooterGuestMob").removeClass("hide");
						}
					}
					cursor_clear();
				}
			});
		},
		declareFooter_controller : function(){
			var containerId = "footerDisplay";
			var contextId = "FooterDisplay_Context";
			var myWidgetObj = $("#" + containerId);
			if (!wcRenderContext.checkIdDefined(contextId)) {
				wcRenderContext.declare(contextId, [], {'isAjaxFlow':'true', 'ext':'ext'});
			}
			wcRenderContext.addRefreshAreaId(contextId, containerId);
			var myRCProperties = wcRenderContext.getRenderContextProperties(contextId);
			myWidgetObj.refreshWidget({
				renderContextChangedHandler: function() {
					myWidgetObj.refreshWidget("refresh", myRCProperties);
				},
				postRefreshHandler: function(widget) {
					$(window).off('scroll.Footer');
					FooterJs.bindFooterFlyouts();
					
					TSCUtils.requires({js:['javascript/jQuery/widgets']});
					Widget.bindEmailWidget();
					
					if ( TSCUtils.getCookie("inStoreOrder") !== "" ){
						$("#social_connect_container_id").hide();
						$("#ResetSessionBtn").show();
					}
					if($("form[name=cityStateForm]").length){
						FooterJs.bindFooterStoreLocator();
					}
					if (!isGuest) {
						$("#ncFooterRegisteredAbout").removeClass("hide");
						$("#ncFooterRegisteredMob").removeClass("hide");
					}else{
						$("#ncFooterGuestAbout").removeClass("hide");
						$("#ncFooterGuestMob").removeClass("hide");
					}
					cursor_clear();
				}
			});
		},
		bindFooterFlyouts : function(){
			$("#about_us").on("mouseenter", function(){
				FooterJs.showFooterFlyOut("#about_us_div");
			});
			$("#about_us").on("mouseleave", function(){
				FooterJs.hideFooterFlyout("#about_us_div");
			});
			$("#cust_solns").on("mouseenter", function(){
				FooterJs.showFooterFlyOut("#cust_solns_div");
			});
			$("#cust_solns").on("mouseleave", function(){
				FooterJs.hideFooterFlyout("#cust_solns_div");
			});
			$("#know_how_cent_id").on("mouseenter", function(){
				FooterJs.showFooterFlyOut("#know_how_cent_div");
			});
			$("#know_how_cent_id").on("mouseleave", function(){
				FooterJs.hideFooterFlyout("#know_how_cent_div");
			});			
		},
		showFooterFlyOut : function(id){
			if(TSCUtils.getClientWidth() > 768){
				$(id).removeClass("hide");
				$(id).attr("role", "alert");
			}
		},
		hideFooterFlyout : function(id){
			$(id).addClass("hide");
			$(id).attr("role", "");
		},
		bindFooterStoreLocator : function(){
			$("form[name=cityStateForm] #statearrow").on("click", function(){
				FooterJs.validateNSubmitFooterStoreLocator();
			});
			$("form[name=cityStateForm] #ziparrow").on("click", function(){
				FooterJs.validateNSubmitFooterStoreLocator();
			});
			$("form[name=cityStateForm] #zip").keypress( function(event){
				if(event.which == 13){
					FooterJs.validateNSubmitFooterStoreLocator();
				}
			});
			$("form[name=cityStateForm] #text1").keypress( function(event){
				if(event.which == 13){
					FooterJs.validateNSubmitFooterStoreLocator();
				}
			});
			$("form[name=cityStateForm] #f_state").keypress( function(event){
				if(event.which == 13){
					FooterJs.validateNSubmitFooterStoreLocator();
				}
			});
		},
		validateNSubmitFooterStoreLocator : function(){
			//Get user inputs into variables
			var zipCode = $("form[name=cityStateForm] #zip").val().trim();
			$("form[name=cityStateForm] #zip").val(zipCode);
			var cityName = $("form[name=cityStateForm] #text1").val().trim();
			$("form[name=cityStateForm] #text1").val(cityName)
			var stateName = $("form[name=cityStateForm] #f_state").val().trim();
			$("form[name=cityStateForm] #f_state").val(stateName);
			$("#invalid_city_footer").hide();
			$("#invalid_zip_footer").hide();
			$("#required_params_footer").hide();
			if(TSCUtils.isNullObj(zipCode)){
				//Check if city & state details are provided 
				if(TSCUtils.isNullObj(cityName) || TSCUtils.isNullObj(stateName)){
					$("#required_params_footer").show();
					return;
				}else if (TSCUtils.isNumeric(cityName)){
					// If city & state details are provided, check if the city name is valid (has only alphabets) 
					$("#invalid_city_footer").show();
					return;
				}
			}else if(!TSCUtils.isValidZip(zipCode)){
				//If zipcode is non numeric or exceeds 5 digits show error messag
				$("#invalid_zip_footer").show();
				return;
			}
			$("form[name=cityStateForm]").submit();
		}
};


$(document).ready(function(){
	if($("#footerDisplay").length){
		$(window).on('scroll.Footer', function(){
			if(TSCUtils.isElementVisible("footerDisplay") && !FooterJs.footerControllerServiceInvoked){
				wcRenderContext.updateRenderContext('FooterDisplay_Context', {'isAjaxFlow':'true','ext':'ext'});
				FooterJs.footerControllerServiceInvoked = true;
			}
		});
		
		if($("#loadFooter").length && $("#loadFooter").val() == "true"){
			if(TSCUtils.isElementVisible("footerDisplay") && !FooterJs.footerControllerServiceInvoked){
				wcRenderContext.updateRenderContext('FooterDisplay_Context', {'isAjaxFlow':'true','ext':'ext'});
				FooterJs.footerControllerServiceInvoked = true;
			}			
		}
		var expFragFooterLoaded = $("#footerExperienceFragmentLoadedHidden").val();
		if(expFragFooterLoaded != 'true'){
			if (!isGuest) {
				$("#ncFooterRegisteredAbout").removeClass("hide");
				$("#ncFooterRegisteredMob").removeClass("hide");
			}else{
				$("#ncFooterGuestAbout").removeClass("hide");
				$("#ncFooterGuestMob").removeClass("hide");
			}	
		}		
	}
	
	if($("#fragmentFooterDisplay").length){
		$(window).on('scroll.Footer', function(){
			if(TSCUtils.isElementVisible("fragmentFooterDisplay") && !FooterJs.footerControllerServiceInvoked){
				wcRenderContext.updateRenderContext('FooterDisplay_Context', {'isAjaxFlow':'true','ext':'ext'});
				FooterJs.footerControllerServiceInvoked = true;
			}
		});
		
		if(($("#loadFooter").length && $("#loadFooter").val() == "true") || 
				(($(document).height() - 100) < $(window).height())){
			if(TSCUtils.isElementVisible("fragmentFooterDisplay") && !FooterJs.footerControllerServiceInvoked){
				wcRenderContext.updateRenderContext('FooterDisplay_Context', {'isAjaxFlow':'true','ext':'ext'});
				FooterJs.footerControllerServiceInvoked = true;
			}			
		}
		var isGuest = !(TSCUtils.getCookie('isRegisteredUser') === 'Y');
		if (!isGuest) {
			$("#ncFooterRegisteredAbout").removeClass("hide");
			$("#ncFooterRegisteredMob").removeClass("hide");
		}else{
			$("#ncFooterGuestAbout").removeClass("hide");
			$("#ncFooterGuestMob").removeClass("hide");
		}	
	}
	
});


function submitEmailSignupTI(emailId){
	utag.link({
		"customer_email" :emailId,
		"event_type" : "conversion", 
		"conversion_id" : "Email SignUP", 
		"conversion_category" : "Email SignUP",
		"conversion_action_type" : '2'
	});
};
			 
/**
 * To load the social connect widgets based on the protocol .
 * @return
 */
function socialConnectOnload(socialConnectJSUrl) { 
	 var socialConnect = document.createElement("script");
	 socialConnect.src = socialConnectJSUrl;
	 document.body.appendChild(socialConnect);
 }	
/**
 * To load the gigya url based on protocol
 * @return
 */
function loadGigyaURL(nonSecureGigyaUrl, secureGigyaUrl) { 
	try{
		 var protocol = window.location.protocol;
		 var element = document.createElement("script");
		 var socialWidgetUrl = nonSecureGigyaUrl;
		 if(protocol.indexOf("https:")> -1){
			 socialWidgetUrl = secureGigyaUrl;
		 }
		 element.src = socialWidgetUrl;		 
		 document.body.appendChild(element);			 
	}catch(e){
		//try catch added to resolve any issue caused by gigya not loading.
		console.log("Gigya not loaded"+e.stack);
	}		 
}	
/**
  * To load the gigya url based on protocol
  * @return
  */
 function loadStayConnectedIcons() { 
	var protocol = window.location.protocol;
	var element = document.createElement("script");
	var socialWidgetUrl = nonSecureGigyaUrl;
	if(protocol.indexOf("https:")> -1){
	 socialWidgetUrl = secureGigyaUrl;
	}
   L(socialWidgetUrl,function () {
	L(socialConnectURL,function () {
	});
	 /*if(typeof isPDPPage !== 'undefined') {
		loadShareBarPlugin();
	}*/
   });
  } 
