//-----------------------------------------------------------------
// Licensed Materials - Property of IBM
//
// WebSphere Commerce
//
// (C) Copyright IBM Corp. 2011, 2013 All Rights Reserved.
//
// US Government Users Restricted Rights - Use, duplication or
// disclosure restricted by GSA ADP Schedule Contract with
// IBM Corp.
//-----------------------------------------------------------------

if(typeof(QuickInfoJS) == "undefined" || QuickInfoJS == null || !QuickInfoJS) {

	QuickInfoJS = {
		productImgDimensions: "1000x1000",
		quickInfoImgDimensions: "330x330",
		catEntryParams: {},
		selectedAttributes: new Object(),
		catEntryQuantity: 1,
		itemDetailsArr: new Object(),
		params: null,
		replaceOrderItemId: '', // the orderItemId to be replaced after changing the attributes
		itemId: '', // the current item in the cart to be replaced
		selectedSkuIndex: null, // selected sku index
		selectedThumbnailIndex: null, // selected thumbnail index
		fromATCOverlay : false,

		/**
		 * Sets the replaceOrderItemId
		 */
		setReplaceOrderItemId: function(replaceOrderItemId){
			this.replaceOrderItemId = replaceOrderItemId;
		},

		/**
		 * Sets other values
		 */
		setValues: function(){
			if($("#catEntryParamsForJS").length){
				this.catEntryParams = jQuery.parseJSON($("#catEntryParamsForJS").val())
			}
			HeaderJs.refkATCObj.event_name = "qv";
			this.catEntryParams.attributes = this.selectedAttributes;
		},

		/**
		 * Fetches the selected defining attributes onClick of view details
		 * button in quick overlay and append the href with required parameter
		 * values
		 */
		extractAttr : function() {
			var attrParam = JSON.stringify(this.selectedAttributes).replace("{","").replace("}","").replace(/\"/g,"");
			var baseURL = $("#WC_QuickInfo_Link_viewdetails").attr("href").split("#")[0];
			$(location).attr("href", baseURL+"#"+attrParam);
		},
		/** Setter for catEntryQuantity
		 *
		 * @param Integer catEntryQuantity
		 */
		setCatEntryQuantity: function(catEntryQuantity){
			this.catEntryQuantity = catEntryQuantity;
		},
		declareQV_controller: function(){
			var containerId = "quickInfoRefreshArea";
			var contextId = containerId;
			var myWidgetObj = $("#" + containerId);
			var contextValues = this.setCommonParams();
			if (!wcRenderContext.checkIdDefined(contextId)) {
				wcRenderContext.declare(contextId, [], {productId: "", updateAttributes: "false"});
			}
			
			wcRenderContext.addRefreshAreaId(contextId, containerId);
			var myRCProperties = wcRenderContext.getRenderContextProperties(contextId);
			
			myWidgetObj.refreshWidget({
				/** 
				 * Refreshes the Intelligent Offer Recommendations e-Marketing Spot area.
				 * This function is called when a render context changed event is detected. 
				 */
				renderContextChangedHandler: function() {
					myWidgetObj.refreshWidget("refresh", myRCProperties);
				},
				/** 
				 * Post handling for the Intelligent Offer Recommendations e-Marketing Spot area.
				 * This function is called after a successful refresh.
				 */
				/**
				 * Hide the progress bar upon a successful refresh.
				 *
				 * @param {object} widget The registered refresh area
				 */
				postRefreshHandler: function(widget) {

					if($("#catEntryParamsForJS").length){
						QuickInfoJS.catEntryParams = jQuery.parseJSON($("#catEntryParamsForJS").val())
					}
					if ($("#quickInfoPopup").length) {
						var productId = myRCProperties['productId'];
						var params = [];
						params.productId  = productId;
						var suffix = "quickinfo";
						var solrHostUrls = $("#solrHostUrls").val().split("|");						
						//var solrHostUrl = $("#solrHostUrlPath").val();
						var solrHostUrl = solrHostUrls[0];
						var currentUrl = window.location.href;
						if(typeof solrHostUrls[1] != "undefined" && solrHostUrls[1].length > 0 ){
							var res = solrHostUrls[1].split(":3738");
							if(typeof currentUrl != "undefined" && currentUrl.length > 0 && currentUrl.indexOf(res[0]) !== -1){
								solrHostUrl = solrHostUrls[1];
							}
						}
						var solrItemPriceUrl = $("#solrItemPriceUrlPath").val();
						//Add call for QuickInfo
						//QuickInfoJS.updatePriceForCatentry(productId,'quickinfo');
						var hasSingleSKUVal = $("#hiddenHasSingleSKUVal_"+productId).length ? $("#hiddenHasSingleSKUVal_"+productId).val() : "";
						var hasMappPrice = $("#hasMappPrice_"+productId).length ? $("#hasMappPrice_"+productId).val() : "";
						if($("#storePriceAttribute_"+productId) && $("#storePriceAttribute_"+productId).val() == 'true'){
							params.stlocId = TSCUtils.getCookie("lpStoreNum");
							if(hasSingleSKUVal == "false" && hasMappPrice == "true" ){
								params.hasMultiVariantMapp = "Y";
								var url = solrHostUrl+solrItemPriceUrl+'productId='+params.productId+'&stlocId='+params.stlocId+'&hasMultiVariantMapp='+params.hasMultiVariantMapp;
								$.ajax({
								    type : "GET",
								    url : url,
								    data : params, //name of the serialize form
								    dataType:"json",
								    success: function(data){
										TSCCommonPriceDisplayJS.setMultivariantItemPriceDetails(data, suffix);
								    },
								 	error: function(data) {
								    	console.log("Error while fetching price during Store price Solr call in Search");
								    }
								});
							} else {
								params.hasMultiVariantMapp = "N";
								var url = solrHostUrl+solrItemPriceUrl+'productId='+params.productId+'&stlocId='+params.stlocId+'&hasMultiVariantMapp='+params.hasMultiVariantMapp;
								$.ajax({
								    type : "GET",
								    url : url,
								    data : params, //name of the serialize form
								    dataType:"json",
								    success: function(data){
										TSCCommonPriceDisplayJS.setItemPriceDetails(data, suffix);
								    },
								 	error: function(data) {
								    	console.log("Error while fetching price during Store price Solr call in Search");
								    }
								});
							}
						}
						else {
							params.stlocId = "10151";
							if(hasSingleSKUVal == "false" && hasMappPrice == "true" ){
								params.hasMultiVariantMapp = "Y";
								var url = solrHostUrl+solrItemPriceUrl+'productId='+params.productId+'&stlocId='+params.stlocId+'&hasMultiVariantMapp='+params.hasMultiVariantMapp;
								$.ajax({
								    type : "GET",
								    url : url,
								    data : params, //name of the serialize form
								    dataType:"json",
								    success: function(data){
										TSCCommonPriceDisplayJS.setMultivariantItemPriceDetails(data, suffix);
								    },
								 	error: function(data) {
								    	console.log("Error while fetching price during Store price Solr call in Search");
								    }
								});
							} else {
								params.hasMultiVariantMapp = "N";
								var url = solrHostUrl+solrItemPriceUrl+'productId='+params.productId+'&stlocId='+params.stlocId+'&hasMultiVariantMapp='+params.hasMultiVariantMapp;
								$.ajax({
								    type : "GET",
								    url : url,
								    data : params, //name of the serialize form
								    dataType:"json",
								    success: function(data){
										TSCCommonPriceDisplayJS.setItemPriceDetails(data, suffix);
								    },
								 	error: function(data) {
								    	console.log("Error while fetching price during Store price Solr call in Search");
								    }
								});
							}
						}
						closeAllDialogs();
						if(QuickInfoJS.itemId === ''){
							QuickInfoJS.setCatEntryQuantity(1);
							QuickInfoJS.selectDefaultSwatch();
						} else {
							QuickInfoJS.selectCurrentAttributes();
						}
						var catentry_id = QuickInfoJS.catEntryParams.id;
						var selectedColor=$("#selectedSwatchColor_"+catentry_id).val();
						if(!TSCUtils.isNullObj(selectedColor)){
							if(selectedColor.indexOf("'")>-1){
								selectedColor=selectedColor.replace("'","\\\'");
							}
							if($("div.swatchContainer div a[title='"+selectedColor+"']").length){
								$("div.swatchContainer div a[title='"+selectedColor+"']")[0].click();
							}
						}
						
						$("#quickInfoPopup").modal();
						var count= $("#atc_attr_count").length ? eval($("#atc_attr_count").html()): 0;
						for(i=1;i<=count;i++){
							if($("#atc_script_"+i).length){
								eval($("#atc_script_"+i).html());
							}
						}

						// disable dialog re-position for ios and android right after the dialog is opened, this is to avoid virtual keyboard conflict
//						if (ios || android) {
//							quickInfoPopup._relativePosition = new Object();
//						}

					}else{
						console.debug("quickInfoPopup does not exist");
					}
					$("#quickInfoAttrValue_1").css("background-color","#F0F0F0");
					cursor_clear();
				}
			});
		},

		/**
		 * To display quick info details
		 *
		 * @param {String} productId
		 * @param {Object} params
		 * @param {String} itemId
		 * @param {String} quantity
		 */
		showDetails: function(productId, params, itemId, quantity){
			/*
			 * If showDetails is called from merchandising association,
			 * params will be filled with cat entry of the first product to be added to shopping cart
			 */
			if(params){
				this.params = params;
			} else {
				// resetting to null, if no params passed
				this.params = null;
			}
			// Clearing the selected attributes
			this.selectedAttributes = new Object();
			this.selectedSkuIndex = null; // clearing selected sku index
			this.selectedThumbnailIndex = null; // clearing selected thumbnail index

			// For Handling multiple clicks.
			/*if(!submitRequest()){
				return;
			}*/
			//this.close();
			cursor_wait();

			var contextValues = this.setCommonParams();
			if(itemId){
				this.itemId = itemId;
				contextValues.updateAttributes = "true";
			} else {
				this.itemId = '';
				contextValues.updateAttributes = "false";
			}

			if(quantity){
				this.catEntryQuantity = quantity;
			}

			contextValues.productId = productId;
//			wc.render.updateContext('QuickInfoContext', contextValues);
			wcRenderContext.updateRenderContext('quickInfoRefreshArea',contextValues);
			$("productIdQuickInfo").html(productId)
			
		},

		/**
		 * To display quick info details - to change attribute values
		 *
		 * @param {String} orderItemId
		 * @param {String} productId
		 * @param {String} itemId
		 * @param {String} quantity
		 */
		changeAttributes: function(orderItemId, productId, itemId, quantity){
			this.setReplaceOrderItemId(orderItemId);
			this.showDetails(productId, null, itemId, quantity);
		},

		/**
		 * Change the main image based on the angle thumbnail clicked
		 *
		 */
		changeImage: function(elementId, imgSrc){
			this.selectedThumbnailIndex = elementId-1;
			$("#quickInfoMainImage").attr("src",this.getQuickInfoImage(this.catEntryParams.skus[this.selectedSkuIndex].fullImageAttachments[this.selectedThumbnailIndex].path));
			$(".widget_quick_info_popup .other_views li").removeClass("selected");
			$("#quickInfoThumbnail"+elementId).addClass("selected")
		},

		/**
		* setSelectedAttribute Sets the selected attribute value for a particular attribute not in reference to any catalog entry.
		*					   When an attribute is selected from that drop down this method is called to update the selected value for that attribute.
		*
		* @param {String} selectedAttributeName The name of the attribute.
		* @param {String} selectedAttributeValue The value of the selected attribute.
		*
		*/
		setSelectedAttribute : function(selectedAttributeName , selectedAttributeValue){
			this.selectedAttributes[selectedAttributeName] = selectedAttributeValue;
			if(selectedAttributeName == "Size" || selectedAttributeName == "Sex"){
				//Update size Label Value
				if(selectedAttributeValue == ""){
					var selAttrName = selectedAttributeName.toLowerCase();
					$("#selected"+selectedAttributeName+"Text").html("Select a "+selAttrName);
				} else{	
					if($("[aria-label='Size']").length >= 1 || $("[aria-label='Sex']").length >= 1){
						$("#selected"+selectedAttributeName+"Text").html(selectedAttributeValue);
					}
				}
			}
			if($('#colorSizeError').length){
				$('#colorSizeError').addClass("hide");
			}
		},

		/**
		 * To notify the change in attribute to other components that is subscribed to attributesChanged event.
		 */
		notifyAttributeChange: function(){
			//TODO: get rid of dojo subscribe references 
			//dojo.publish('QuickInfo_attributesChanged', [dojo.toJson(this.selectedAttributes)]);
			TSCUtils.publish('QuickInfo_attributesChanged', [JSON.stringify(this.selectedAttributes)]);
		},


		/**
		 * Stores the list of selected attribute IDs
		 */
		attrSelectorArray: [],
		/**
		 * Stores the list of selected attribute aria-label values
		 */
		attrSelectorLabelArray: [],
		/**
		 * Method is used for displaying the Unavailable message
		 */
		populateOOSMessage: function(optionObj, isRemove) {
			var selObjContent = optionObj.innerHTML;
            var spanIdx = selObjContent.indexOf('<span');
            if (isRemove && spanIdx != -1) {
                  var content = selObjContent.substring(0, spanIdx);
                  optionObj.innerHTML = content;
                  if(TSCUtils.byId(content.trim())) {
                        content = content.trim();
                        TSCUtils.removeClass(content,"unavailable");
                        var sector = TSCUtils.getInnerHTML(content);
                        var afterIdx = sector.indexOf('<div class="after"');
                        if(afterIdx!=-1){
                              TSCUtils.setInnerHTML(content , sector.substring(0, afterIdx)) ;
                        }
                  }
            }
            if (!isRemove && spanIdx == -1) {
                  if(TSCUtils.byId(optionObj.innerHTML.trim())) {
                        var content = optionObj.innerHTML.trim();
                        TSCUtils.addClass(content,"unavailable");
                        var sector = TSCUtils.getInnerHTML(content);
                        var afterIdx = sector.indexOf('<div class="after"');
                        if(afterIdx==-1){
                            var sectorContent =   sector + '<div class="after" title="'+optionObj.innerHTML.trim()+'"><svg height="50" width="50">' 
                              + '<line x1="0" y1="0" x2="50" y2="50" style="stroke:#ccc;stroke-width:1" /></svg></div>';
                              TSCUtils.setInnerHTML(content , sectorContent); 
                        }
                  }
                  optionObj.innerHTML = optionObj.innerHTML + "<span><i> - Unavailable</i></span>";
            }
      

		},
		

		/**
		 * This method enables/disables the defining attributes in ATC Overlay  based on items returned by SOLR. This method is  keeping
		 * for future enhancement of ATC overlay in PLP page.Currently is not calling any where.(5/28/2015) as part of Jira :PE - 4563
		 */
		notifyAttributeChangeforSKUresolve : function(count) {
			clearStatusMessage();
			// Get values of the current selected defining attribute
			var currentAttrObjectId = "quickInfoAttrValue_" + count;
			var currentSelectedAttrObj = document
					.getElementById(currentAttrObjectId);
			var currentSelectedAttrVal = currentSelectedAttrObj.options[currentSelectedAttrObj.selectedIndex].value;
			var label = currentSelectedAttrObj.getAttribute("aria-label");
			// var label = currentSelectedAttrObj.get("aria-label");
			var index = -1;
			for ( var i in QuickInfoJS.attrSelectorArray) {
				if (QuickInfoJS.attrSelectorArray[i] == currentAttrObjectId) {
					index = i;
					break;
				}
			}
			// var index =
			// productDisplayJS.attrSelectorArray.indexOf(currentAttrObjectId);
			// Keep track of the selection of defining attributes
			if (index == -1 && currentSelectedAttrVal !== "") {
				QuickInfoJS.attrSelectorArray[QuickInfoJS.attrSelectorArray.length] = currentAttrObjectId;
				QuickInfoJS.attrSelectorLabelArray[QuickInfoJS.attrSelectorLabelArray.length] = label;
			}
			if (index != -1 && currentSelectedAttrVal === "") {
				QuickInfoJS.attrSelectorArray.splice(index, 1);
				QuickInfoJS.attrSelectorLabelArray.splice(index, 1);
			}
			for ( var i in this.catEntryParams.skus) {
				var x_attr = this.catEntryParams.skus[i];
				x_attr.enabled = "true";
			}
			var doProcess = true;
			if (QuickInfoJS.attrSelectorArray.length == 0) {
				var definingAttrObjects = document
						.getElementsByName('quickInfoAttrValue');
				// var definingAttrObjects =
				// dojo.query('.dijitSelect').map(dijit.byNode);
				for ( var i = 0; i < definingAttrObjects.length; i++) {
					if (definingAttrObjects.options != undefined) {
						for ( var j = 0; j < definingAttrObjects.options.length; j++) {
							definingAttrObjects.options[j].disabled = false;
							// definingAttrObjects.options[j].style.color =
							// '#000000';
							definingAttrObjects.selectedIndex = 0;
							QuickInfoJS.populateOOSMessage(definingAttrObjects.options[j], true);
						}
					}
				}
				doProcess = false;
			}

			var isFirst = true;
			// for the selected attribute values, enable/disable the
			// entitledItems.
			for ( var k in QuickInfoJS.attrSelectorArray) {
				var objId = QuickInfoJS.attrSelectorArray[k];
				var selectedAttrObj = document.getElementById(objId);
				if (selectedAttrObj != null) {
					// var selectedAttrObj = dijit.byId(objId);
					var selectedAttrVal = selectedAttrObj.options[selectedAttrObj.selectedIndex].value;
					// var selectedAttrVal = selectedAttrObj.value;
					var selectedAttrLabel = QuickInfoJS.attrSelectorLabelArray[k];
					if (isFirst) {
						for ( var j = 0; j < selectedAttrObj.options.length; j++) {
							selectedAttrObj.options[j].disabled = false;
							QuickInfoJS.populateOOSMessage(selectedAttrObj.options[j], true);
						}
						isFirst = false;
					} else {
						for ( var j = 0; j < selectedAttrObj.options.length; j++) {
							var optionVal = selectedAttrObj.options[j].value;
							var key = selectedAttrLabel;
							var found = false;

							if (optionVal == "") {
								found = true;
							} else {
								for ( var i in this.catEntryParams.skus) {
									var x_attr = this.catEntryParams.skus[i];
									if (x_attr.enabled == "true") {
										var attribute = x_attr.attributes;
										if (attribute[key] == optionVal) {
											found = true;
											break;
										}
									}
								}
							}
							if (found) {
								QuickInfoJS.populateOOSMessage(selectedAttrObj.options[j], true);
								selectedAttrObj.options[j].disabled = false;
							} else {
								QuickInfoJS.populateOOSMessage(selectedAttrObj.options[j], false);
								selectedAttrObj.options[j].disabled = true;
							}

						}
						// selectedAttrObj.reset();
					}
				}
				var attributeKey = selectedAttrLabel;

				for ( var i in this.catEntryParams.skus) {
					var x_attr = this.catEntryParams.skus[i];
					if (x_attr.enabled == "true") {
						var attribute = x_attr.attributes;
						if (attribute[attributeKey] == selectedAttrVal
								|| selectedAttrVal == "") {
							x_attr.enabled = "true";
						} else {
							x_attr.enabled = "false";
						}
					}
				}
			}

			if (doProcess) {
				var definingAttrObjects = document
						.getElementsByName('quickInfoAttrValue');
				// var definingAttrObjects =
				// dojo.query('.dijitSelect').map(dijit.byNode);
				for ( var i = 0; i < definingAttrObjects.length; i++) {
					var definingAttrObj = definingAttrObjects[i];
					var definingAttrSelVal = definingAttrObj.options[definingAttrObj.selectedIndex].value;
					// var definingAttrSelVal = definingAttrObj.value;
					var definingAttrLabel = definingAttrObj
							.getAttribute("aria-label");
					// var definingAttrLabel =
					// definingAttrObj.get("aria-label");
					for ( var j = 0; j < definingAttrObj.options.length; j++) {
						var optionVal = definingAttrObj.options[j].value;
						if (optionVal == definingAttrSelVal
								&& definingAttrObj.options[j].disabled) {
							// if this option = selected attr value but has been
							// disabled for the current combination, then reset
							// this attribute drop-down.
							definingAttrObj.selectedIndex = 0;
							this.setSelectedAttribute(definingAttrLabel,"");
							if(definingAttrLabel == 'Color') {
								$(".swatch .selected").removeClass("selected");
								$("#selectedcolorText").html("");
							}
							break;
						} else if (definingAttrSelVal == "") {
							definingAttrObj.options[j].disabled = false;
							var key = definingAttrLabel;
							var found = false;
							if (optionVal == "") {
								found = true;
							} else {
								for ( var k in this.catEntryParams.skus) {
									var x_attr = this.catEntryParams.skus[k];
									if (x_attr.enabled == "true") {
										var attribute = x_attr.attributes;
										if (attribute[key] == optionVal) {
											found = true;
											break;
										}

									}
								}
							}
							if (found) {
								QuickInfoJS.populateOOSMessage(definingAttrObj.options[j], true);
								definingAttrObj.options[j].disabled = false;
							} else {
								QuickInfoJS.populateOOSMessage(definingAttrObj.options[j], false);
								definingAttrObj.options[j].disabled = true;
							}
						}
					}
				}
			}
			// invoke the OOB logic for resolving SKU.
			QuickInfoJS.notifyAttributeChange();
			
		},

		/**
		 * Selects the sku and updates the item name and price.
		 *
		 * @param {Boolean} displayPriceRange If the value is true, then display the price range. If it is false then donot display the price range.
		 * @param {Boolean} updateItemImageOnly If the value is true, then only change the item image. If it is false then update all product details.
		 */
		selectItem: function(displayPriceRange, updateItemImageOnly){
			this.displayPriceRange = displayPriceRange;
			this.setValues();
			var catEntryId = this.resolveSKU();
			$("#OOSMessage").addClass("hide");
			
			if(catEntryId == -1 && updateItemImageOnly){
				var skuId = -1;
				for(idx=0;idx<this.catEntryParams.skus.length;idx++){
					for(attribute in this.catEntryParams.skus[idx].attributes){
						if(this.catEntryParams.attributes && 
								decodeSpecialCharacters(this.catEntryParams.skus[idx].attributes[attribute]) == 
									decodeSpecialCharacters(this.catEntryParams.attributes[attribute])){
							skuId = this.catEntryParams.skus[idx].id;
							break;
						}
					}
				}
				if(skuId != -1){
					catEntryId = skuId;
					this.updateItemImageOnly = updateItemImageOnly;
				}
			}

			if(catEntryId!=-1){

				//check if the object is already present for the catEntryId.
				if(!TSCUtils.isNullObj(this.itemDetailsArr[catEntryId])){

					this.displayItemDetails(this.itemDetailsArr[catEntryId]);
				}
				//if json object is not present, call the service to get the details.
				else{

					var params = this.setCommonParams();
					params.catalogEntryId = catEntryId;
					params.langId = -1;

					$.ajax({
					    type : "POST",
					    url : getAbsoluteURL() + "TSCFetchItemDetails",
					    data : params, //name of the serialize form
					    dataType:"text",
					    success: function(data){
							//console.log(data); //output what's returned from submitform.php
							data = data.replace(/<!--[\s\S]*?-->/g, '');
							data = jQuery.parseJSON(data.replace(/(\/\*|\*\/)/g, ''));
							console.log(data);
					        QuickInfoJS.setItemDetails(data);
					    },
					 	error: function(data) {
					    	console.log("QuickInfoJS.selectItem: Unexpected error occurred during an xhrPost request.");
					    }
					});
				}
			}
			 else{
				 $("#qv_addtocart_btn").prop("disabled",true);
				 $("#qv_addtocart_btn").removeClass("lb_addtocart_btn");
				 $("#qv_addtocart_btn").addClass("lb_cancel_btn");
			 }

		},

		/**
		* Handles the case when a swatch is selected. Set the border of the selected swatch.
		* @param {String} selectedAttributeName The name of the selected swatch attribute.
		* @param {String} selectedAttributeValue The value of the selected swatch attribute.
		* @param {String} swatchId id of the swatch element
		* @param {String} swatchGrp starting pattern of id of the swatch element group
		*
		**/
		selectSwatch: function(selectedAttributeName, selectedAttributeValue, swatchId, swatchGrp) {
			if($("[id='quickInfoSwatch_" + selectedAttributeName + "_" + selectedAttributeValue + "']").hasClass("color_swatch_disabled")){
				return false;
			}
			// sets the store parameters and catalog details from the hidden fields
			this.setValues();
			// picks all the swatch names
			var swatchNames = $("#WC_QuickInfo_SwatchNames").val().split("_");
			// identifies the position of the current swatch
			var currentSwatchPos = -1;
			for(var i=0; i<swatchNames.length;i++){
				if(swatchNames[i] == selectedAttributeName){
					currentSwatchPos = i;
					break;
				}
			}
			var currentSwatchkey = "quickInfoSwatch_" + selectedAttributeName + "_" +selectedAttributeValue;
			var currentSwatchkeyLink = "WC_QuickInfo_Swatch_" + selectedAttributeName + "_" +selectedAttributeValue;
			var validSwatchArr = new Array();
			// iterates through each sku and its attributes to identify valid attribute combination
			for(idx in this.catEntryParams.skus){
				var validItem = false;
				var entitledItem = this.catEntryParams.skus[idx];
				for(attribute in entitledItem.attributes){
					
					if(selectedAttributeName == attribute && selectedAttributeValue == entitledItem.attributes[attribute] && entitledItem.buyable){
						validItem = true;
						break;
					}
				}
				if(validItem){
					for(attribute in entitledItem.attributes){
						var attributePos = -1;
						for(var i=0; i<swatchNames.length;i++){
							if(swatchNames[i] == attribute){
								attributePos = i;
								break;
							}
						}						
						if(attributePos > currentSwatchPos){
							// picks the valid swatch attributes for the current swatch selection
							validSwatchArr.push(attribute + "_" + entitledItem.attributes[attribute]);
						}
					}
				}
			}
			
			var swatchesDisabled = new Array();
			var selectedSwatches = new Array();
			for(idx in this.catEntryParams.skus){
				var entitledItem = this.catEntryParams.skus[idx];
				for(attribute in entitledItem.attributes){
					var attributePos = -1;
					for(var i=0; i<swatchNames.length;i++){
						if(swatchNames[i] == attribute){
							attributePos = i;
							break;
						}
					}											
					if(attributePos > currentSwatchPos){
						var swatchSelection = attribute + "_" + entitledItem.attributes[attribute];
						var swatchId = "quickInfoSwatch_" + swatchSelection;
						var swatchLinkId = "WC_QuickInfo_Swatch_" + swatchSelection;
						var validSwatchArrPos = -1;
						for(var i=0; i<validSwatchArr.length;i++){
							if(validSwatchArr[i] == swatchSelection){
								validSwatchArrPos = i;
								break;
							}
						}								
						var swatchesDisabledPos = -1;
						for(var i=0; i<swatchesDisabled.length;i++){
							if(swatchesDisabled[i] == swatchSelection){
								swatchesDisabledPos = i;
								break;
							}
						}								
						// enable valid swatches
						if(validSwatchArrPos > -1){
							if(!$("[id='" + swatchId + "']").hasClass("color_swatch_selected")){
								$("[id='" + swatchId + "']").attr("class", "color_swatch");
								$("[id='" + swatchId + "']").attr("src", $("[id='" + swatchId + "']").attr("src").replace("_disabled.png","_enabled.png"))
								$("[id='" + swatchLinkId + "']").attr("aria-disabled", "false");
							}
						} 
						// disable invalid swatches
						else if(swatchesDisabledPos == -1){
							swatchesDisabled.push(swatchSelection);
							if($("[id='" + swatchId + "']").hasClass("color_swatch_selected")){
								selectedSwatches.push(swatchId);
							}
							$("[id='" + swatchId + "']").attr("class", "color_swatch_disabled");
							$("[id='" + swatchId + "']").attr("src", $("[id='" + swatchId + "']").attr("src").replace("_enabled.png","_disabled.png"))
							$("[id='" + swatchLinkId + "']").attr("aria-disabled", "true");
						}
					}
					$("[id='WC_QuickInfo_Swatch_" + attribute + "_" + entitledItem.attributes[attribute] + "']").attr("aria-checked", "false");
				}
			}
			
			// if any of the disabled swatches were previously selected, change the selection to the first valid value
			for(idx in selectedSwatches){
				var selectedSwatch = selectedSwatches[idx];
				var idSelector = "img[id^='" + selectedSwatch.substring(0, selectedSwatch.lastIndexOf("_")) + "']";
				var swatchSelected = false;
				$(idSelector).each(function(index, node, arr){
					if(!swatchSelected && $(node).hasClass("color_swatch")){
						var values = node.id.split("_");
						QuickInfoJS.selectSwatch(values[1],values[2], "quickInfoSwatch_" + values[1] + "_" + values[2],"quickInfoSwatch_"+values[1]+"_");
						QuickInfoJS.selectItem(true);
						swatchSelected = true;
					}
				});
			}
			
			selector = "img[id^='"+swatchGrp+"']";
			
			$(selector).each(function(index, node, arr){
			    if(!$(node).hasClass("color_swatch_disabled")){
			    	$(node).addClass("color_swatch");
			    	if($(node).hasClass("color_swatch_selected")){
				    	$(node).removeClass("color_swatch_selected");
				    }
			    }
			});
			
			$("[id='" + currentSwatchkey + "']").attr("class", "color_swatch_selected");
			$("[id='quickinfo_swatch_selection_"+selectedAttributeName + "']").html(selectedAttributeValue)
			$("[id='" + currentSwatchkeyLink + "']").attr("aria-checked", "true");
			this.setSelectedAttribute(selectedAttributeName, selectedAttributeValue);
		},

		/**
		 * Sets the store specific values such as storeId, catalogId and langId in a Object and returns it.
		 *
		 * @return {Object} params with store specific values
		 */
		setCommonParams: function(){
			var params = new Object();
			params.storeId		= WCParamJS.storeId;
			params.catalogId	= WCParamJS.catalogId;
			params.langId		= WCParamJS.langId;
			return params;
		},

		/**
		 * Displays price of the catEntry selected with the JSON object returned from the server.
		 *
		 * @param {object} serviceRepsonse The JSON response from the service.
		 * @param {object} ioArgs The arguments from the service call.
		 */
		setItemDetails: function(serviceResponse) {
			//alert("step 3--"+serviceResponse.catalogEntry);
			QuickInfoJS.itemDetailsArr[serviceResponse.catalogEntry.catalogEntryIdentifier.uniqueID] = serviceResponse.catalogEntry;
			console.log(serviceResponse);
			var storePriceAttribute = serviceResponse.catalogEntry.hasStorePrice;
			QuickInfoJS.displayItemDetails(serviceResponse.catalogEntry,storePriceAttribute);
		},

		displayItemDetails: function(catalogEntry,storePriceAttribute) {
			if(this.updateItemImageOnly){
				this.updateItemImageOnly = false;
				return;
			}
			var listPriceVal = 0.00;
			var offerPriceVal = 0.00;
			var mappPriceVal = 0.00;
			var mappriceMin = "mappprice_min";
			var productId  = catalogEntry.catalogEntryIdentifier.uniqueID;
			var solrHostUrls = $("#solrHostUrls").val().split("|");						
			//var solrHostUrl = $("#solrHostUrlPath").val();
			var solrHostUrl = solrHostUrls[0];
			var currentUrl = window.location.href;
			if(typeof solrHostUrls[1] != "undefined" && solrHostUrls[1].length > 0 ){
				var res = solrHostUrls[1].split(":3738");
				if(typeof currentUrl != "undefined" && currentUrl.length > 0 && currentUrl.indexOf(res[0]) !== -1){
					solrHostUrl = solrHostUrls[1];
				}
			}
			var solrItemPriceUrl = $("#solrItemPriceUrlPath").val();
			var params = [];
			params.productId  = productId;
			params.hasMultiVariantMapp = "N";
			if(storePriceAttribute!=null && storePriceAttribute == 'Y') {
				params.stlocId = TSCUtils.getCookie("lpStoreNum");
				var url = solrHostUrl+solrItemPriceUrl+'productId='+params.productId+'&stlocId='+params.stlocId+'&hasMultiVariantMapp='+params.hasMultiVariantMapp;
				$.ajax({
				    type : "GET",
				    url : url,
				    data : params, //name of the serialize form
				    dataType:"json",
				    success: function(serviceResponseForPrice) {
						var storeId = TSCUtils.getCookie('lpStoreNum');
						if(serviceResponseForPrice.catalogEntryView[0] != null){
							var storePriceObj =  serviceResponseForPrice.catalogEntryView[0];
							var listPriceMin = "list_price_min_"+storeId;
							var offerPriceMin = "offer_price_min_"+storeId;
							if(storePriceObj[listPriceMin]!=null){
								listPriceVal = parseFloat(Math.round(storePriceObj[listPriceMin] * 100) / 100).toFixed(2);
							}
							
							if(storePriceObj[offerPriceMin]!=null ){
								offerPriceVal = parseFloat(Math.round(storePriceObj[offerPriceMin] * 100) / 100).toFixed(2);
						
							}
							if(storePriceObj[mappriceMin]!=null ){
								mappPriceVal = parseFloat(Math.round(storePriceObj[mappriceMin] * 100) / 100).toFixed(2);
						
							}
							TSCCommonPriceDisplayJS.setQuickInfoSKUDisplayPrice(listPriceVal, offerPriceVal, mappPriceVal, catalogEntry);
						}	
				    },
					error: function(errObj) {
						console.debug("Store price call: Unexpected error occurred during an xhrPost request.");

					}
				});
				
				
			}
			//Making call for fetching the web only price of variant item
			else {
				params.stlocId = "10151";
				var url = solrHostUrl+solrItemPriceUrl+'productId='+params.productId+'&stlocId='+params.stlocId+'&hasMultiVariantMapp='+params.hasMultiVariantMapp;
				$.ajax({
				    type : "GET",
				    url : url,
				    data : params, //name of the serialize form
				    dataType:"json",
				    success: function(serviceResponseForPrice) {
					   if(serviceResponseForPrice.catalogEntryView[0] != null){
							var storePriceObj =  serviceResponseForPrice.catalogEntryView[0];
							var listPriceMin = "list_price_min_10151";
							var offerPriceMin = "offer_price_min_10151";
							if(storePriceObj[listPriceMin]!=null){
								listPriceVal = parseFloat(Math.round(storePriceObj[listPriceMin] * 100) / 100).toFixed(2);
							}
							
							if(storePriceObj[offerPriceMin]!=null ){
								offerPriceVal = parseFloat(Math.round(storePriceObj[offerPriceMin] * 100) / 100).toFixed(2);
						
							}
							if(storePriceObj[mappriceMin]!=null ){
								mappPriceVal = parseFloat(Math.round(storePriceObj[mappriceMin] * 100) / 100).toFixed(2);
						
							}
							 TSCCommonPriceDisplayJS.setQuickInfoSKUDisplayPrice(listPriceVal, offerPriceVal, mappPriceVal, catalogEntry);
						}	
				    },
					error: function(errObj) {
						console.debug("Store price call: Unexpected error occurred during an xhrPost request.");

					}
				});
			}
			
		},

		validate: function(){
			if($('#colorSizeError').length){
				$('#colorSizeError').addClass("hide");
			}
			if(this.catEntryParams.type =='ProductBean' &&
					(null == this.catEntryParams.attributes || "undefined" == this.catEntryParams.attributes)) {
				MessageHelper.displayErrorMessage(storeNLS['ERR_RESOLVING_SKU']);
				return false;
			} else if(!isPositiveInteger(this.catEntryQuantity)){
				MessageHelper.displayErrorMessage(storeNLS['QUANTITY_INPUT_ERROR']);
				return false;
			}
			if ($('span[id^="selected"]:contains("sex")').length > 0 && !$('span[id^="selected"]:contains("Unsexed")').length > 0) {
				if($('#colorSizeError').length){
					$('#colorSizeError').html("Please Select a sex");
					$('#colorSizeError').removeClass("hide");
					return false;
				}
			}
			else if ($('span[id^="selected"]:contains("Select")').length > 0) {
				if($('#colorSizeError').length){
					$('#colorSizeError').html("Please Select color and / or size");
					$('#colorSizeError').removeClass("hide");
					return false;
				}
			}
			return true;
		},

		/**
		* add2ShopCart Adds displayed product to the shopping cart
		*
		*
		**/
		add2ShopCart: function(customParams){
			this.setValues();
			if(!this.validate()){
				return;
			}
			var addToCartFrom="";
			try {
				if(TSCUtils.byId("data_source")) {
					HeaderJs.refkATCObj.source = $("#data_source").val();
				}
				if(TSCUtils.byId("isHomePage")){
					if (TSCUtils.getValifexists("data_source") == "InStoreHomeRec") {
						addToCartFrom ='INSTOREHOMEREC';
					} else {
						addToCartFrom ='IOHOMEPAGE';
					}
					HeaderJs.refkATCObj.event_name = "rec_home";
				} else if(TSCUtils.byId("isShoppingCart")){
					addToCartFrom ='IOCART';
					HeaderJs.refkATCObj.event_name = "rec_cart";
				} else if(TSCUtils.byId("isCategoryPage")){
					HeaderJs.refkATCObj.event_name = "rec_category";
					addToCartFrom ='IODEPT';
				} else if($("#subcategory_name_TI").length){
			var catalogEntryId_TI = $("#partNumHidden").val();
			if($("#categoryIdHidden_" + catalogEntryId_TI).length){
			  var categoryId_TI = $("#categoryIdHidden_" + catalogEntryId_TI).val();
					  addToCartFrom = "Quick Shop" + categoryId_TI;
			}
			} else if(TSCUtils.getParameterByName("isVoice") === "Y"){
				addToCartFrom = "Quick Shop-10010";
			} else if($("#virtualCategorySearchPage").length){
				addToCartFrom = "Quick Shop-10009";
			} else if($("#customSearchPage").length){
				addToCartFrom = "Quick Shop-10015";
			} else if($("#brandSearchPage").length){
				addToCartFrom = "Quick Shop-10011";
			} else if($("#customSearchPage").length){
				addToCartFrom = "Quick Shop-10015";
			} else if($("#search_type_TI").length){
				addToCartFrom = "Quick Shop-10005";
			} else if($("#compare_page_TI").length){
				addToCartFrom = "compare";
			}
			} catch (e) {
				// Do nothing
			}
			/*
			 * params is null means, no product is waiting to be added to shopping cart.
			 * params will not be null if the call originated from merchandising association
			 */

			this.params = [];
			this.params = this.setCommonParams();
			 
			this.params.orderId	= ".";
			this.params.mergeUpdatedCart = "false";
			this.params.shippingCode = "TSCSTD";
			this.params.shippingCarrier	= "TSC";
			this.params.field1	= "0";
			this.params.stlocId	= getCookie("lpStoreNum");
			this.params.zipCode=TSCUtils.getCookie("lpZipCodeNum");
			//Commenting the oob code as we are not using following calusages in SC page
			//this.params.calculationUsage = "-1,-2,-5,-6,-7";
			this.params.calculationUsage = "-1";
			this.params.inventoryValidation = "true";
			this.params.navigationTrackerTI = addToCartFrom;

			if(addToCartFrom == 'Quick Shop-10005'){				
				var url1 = document.URL;
				if(url1!=null && url1!="undefined"){
					if(url1.indexOf("/search/")>-1 ){
			             var paramURL = url1.split('?')[0].split('#')[0].split('/search/')[1];
			             var searchTerm = paramURL.split('/')[0];
					}
				}
				if(searchTerm!=null && searchTerm!="undefined"){	
					this.params.fromSearchTerm		= searchTerm;
				}
			}
			var productId = '';
			//Add the catalog entry to the cart.
			if(this.catEntryParams.type.toLowerCase() == 'itembean'
				|| this.catEntryParams.type.toLowerCase() == 'packagebean'
				|| this.catEntryParams.type.toLowerCase() == 'dynamickitbean'){
				updateParamObject(this.params,"catEntryId",this.catEntryParams.id,false,-1);
				updateParamObject(this.params,"quantity",this.catEntryQuantity,false,-1);
				productId = this.catEntryParams.id;
				setTeliumParam(productId,'a2c');
			} else {
				// Resolve ProductBean to an ItemBean based on the attributes in the main page
				var sku = this.resolveSKU();
				if(-1 == sku){
					MessageHelper.displayErrorMessage(storeNLS['ERR_RESOLVING_SKU']);
					return;
				} else {
					updateParamObject(this.params,"catEntryId",sku,false,-1);
					updateParamObject(this.params,"quantity",this.catEntryQuantity,false,-1);
				}
				productId = sku;
				setTeliumParam(sku,'a2c');
			}

			var shopCartService = "AddOrderItem";

			//Pass any other customParams set by other add on features
			if(customParams != null && customParams != 'undefined'){
				for(i in customParams){
					this.params[i] = customParams[i];
				}
				if(customParams['catalogEntryType'] == 'dynamicKit' ){
					shopCartService = "AddPreConfigurationToCart";
				}
			}
			if(this.params['catalogEntryType'] == 'dynamicKit' ){
				shopCartService = "AddPreConfigurationToCart";
			}

			shoppingActionsJS.saveAddedProductInfo(this.catEntryQuantity, this.catEntryParams.id, productId, this.selectedAttributes);

			//this.close();

			//For Handling multiple clicks
			if(!submitRequest()){
				return;
			}
			//cursor_wait();
			this.fromATCOverlay = true;
			wcService.invoke(shopCartService, this.params);
		},

		/**
		 * This resolves the product SKUs to a single item by comparing the attributes selected by the user
		 *
		 * @return {Integer} uniqueId, of the selected SKU.
		 * 					 -1, if no match found
		 */
		resolveSKU: function() {
			// if there is only one sku, no need to resolve.
			if(this.catEntryParams.skus.length == 1){
				return this.catEntryParams.skus[0].id;
			}
			for(idx=0;idx<this.catEntryParams.skus.length;idx++){
				var matches = 0;
				var attributeCount = 0;
				for(attribute in this.catEntryParams.skus[idx].attributes){
					attributeCount++;
					if(this.catEntryParams.attributes &&  
							decodeSpecialCharacters(this.catEntryParams.skus[idx].attributes[attribute]) ==
								decodeSpecialCharacters(this.catEntryParams.attributes[attribute])){
						matches++;
					} else {
						break;
					}
				}
				// if there are multiple skus for a product, there should be atleast one attribute for that product
				if(0 != matches && matches == attributeCount){
					return this.catEntryParams.skus[idx].id;
				}
			}
			return -1;
		},

		/**
		 * Displays the Product Quick Info button.
		 *
		 * @param {string} id The id of the div area to show.
		 */
		showQuickInfoButton: function(id){
			$("#"+id).show();
		},

		/**
		 * Hides the Product Quick Info button.
		 *
		 * @param {string} id The id of the div area to hide.
		 */
		hideQuickInfoButton: function(id){
			$("#"+id).hide();
		},

		/**
		 * Overrides the hidePopupButton function above by also checking to see if the user clicks shift+tab.
		 *
		 * @param {string} id The id of the div area to hide.
		 * @param {event} event The keystroke event entered by the user.
		 */
		shiftTabHideQuickInfoButton: function(id, event){
			if ((event.shiftKey) && (event.keyCode == 9)){
				this.hideQuickInfoButton(id);
			}
		},

		/**
		 * Close the quick info popup
		 */
		close: function(focusElement){
			$('#quickInfoPopup').modal('hide');
			if(focusElement !=null && focusElement !='undefined' && document.getElementById(focusElement)){
				document.getElementById(focusElement).focus();
			}
		},


		/**
		 * Sets the focus back to the product image after quick info is closed.
		 */
		setFocus: function(event){
			if(event.keyCode == 27 && $('#catEntryParamsForJS').length){
				var catEntryId = jQuery.parseJSON($("#catEntryParamsForJS").val()).id;
				$("#catalogEntry_img" + catEntryId).focus()
			}
		},


		/**
		 * Selects first swatch element
		 */
		selectDefaultSwatch: function(){
			var swatchElement = $("a[id^='WC_QuickInfo_Swatch_']");
			if(swatchElement.length){
				eval(swatchElement[0].prop("href"));
			}
		},

		/**
		* replaceCartItem This function is used to replace an item in the shopping cart. This will be called from the shopping cart and checkout pages.
		*
		**/
		replaceCartItem : function(){
			this.setValues();
			if(!this.validate()){
				return;
			}

			var catalogEntryId = this.resolveSKU();
			if(-1 == catalogEntryId){
				MessageHelper.displayErrorMessage(storeNLS['ERR_RESOLVING_SKU']);
				return;
			}
			this.close();

			var addressId = "";
			var shipModeId = "";
			var physicalStoreId = "";
			var typeId = TSCUtils.isNullObj($("#shipmentTypeId").val()) ? "1" : $("#shipmentTypeId").val();
			
			if(!(TSCUtils.isNullObj($("#addressId_all").val()) || TSCUtils.isNullObj($("#shipModeId_all").val()) )){
				//Single Shipment..get the common addressId and shipModeId..
				if(typeId !== "1" ){
					addressId = $("#addressId_all").val();
				}else if(!TSCUtils.isNullObj($("#physicalStoreId").val())) {
					physicalStoreId = $("#physicalStoreId").val()
				}
				shipModeId = $("#shipModeId_all").val();
			} else if($("#MS_ShipmentAddress_"+this.replaceOrderItemId).val() != null &&  $("#MS_ShippingMode_"+this.replaceOrderItemId).val() != null){
				//Multiple shipment..each orderItem will have its own addressId and shipModeId..
				addressId = $("#MS_ShipmentAddress_"+this.replaceOrderItemId).val();
				shipModeId = $("#MS_ShippingMode_"+this.replaceOrderItemId).val();
			}

			if(this.replaceOrderItemId != "" && categoryDisplayJS){
				//Else remove existing catEntryId and then add new one...
				shoppingActionsJS.replaceItemAjaxHelper(catalogEntryId,this.catEntryQuantity,this.replaceOrderItemId,addressId,shipModeId,physicalStoreId);
			} else {
				console.error("categoryDisplayJS not defined");
			}
		},

		/**
		 * select the attributes of an sku
		 */
		selectCurrentAttributes: function(){
			for(idx=0;idx<this.catEntryParams.skus.length;idx++){
				var selectedSKU = this.catEntryParams.skus[idx];
				if(selectedSKU.id === this.itemId){
					for(attribute in selectedSKU.attributes){
						var selectNode = $("select[alt='" + attribute + "']")[0];
						if(selectNode.length){
							selectNode.val(selectedSKU.attributes[attribute]);
							this.setSelectedAttribute(attribute,selectNode.val());
						} else {
							var attrValue = selectedSKU.attributes[attribute];
							this.selectSwatch(attribute, attrValue, "quickInfoSwatch_" + attribute + "_" + attrValue, "quickInfoSwatch_" + attribute + "_");
						}
					}

					this.notifyAttributeChange();
					this.selectItem(true);
					$("WC_QuickInfo_input_quantity").val(this.catEntryQuantity).prop("disabled",true);
					return;
				}
			}

		},

		/**
		* Given full image path, this function replaces the product image dimensions with quick info image dimensions..
		* For Ex: From 1000x1000, to 330x330
		**/
		getQuickInfoImage:function(fullImage){
			if(fullImage === "") return fullImage;
			return fullImage.replace(this.productImgDimensions, this.quickInfoImgDimensions);

		},

		
		/**
		 * Make SELECT the Color-Value in the Color Dropdown.
		 */
		setColorDropDown:function(id,value){
		 	$("#"+id).val(value);
		 	$("#"+id+" option").each(function(){
		 		if(decodeSpecialCharacters($(this).val()) === decodeSpecialCharacters(value)){
		 			$(this).prop("selected", true);
		 			return false;
		 		}
		 	})
		},
		/**
		 * This method updates the quick info main image with selected swatch image.
		 */
		setSwatchSelected:function(swatchId,colorValue){		 	
		 	$(".swatch .selected").removeClass("selected");
		 	$("#swatch_"+swatchId).addClass("selected");
		 	
			//Update Color Label Value
		 	$("#selectedcolorText").html(colorValue);
		 	
		 	//Update QuickInfo Image
		 	var img = $('#swatch_img_'+swatchId).prop("src");	
			if(img != null && img.indexOf("prod_det")<=-1){ 
		 		img = img + "?$prod_det$";
		 	}
		 	$('#quickInfoImg').prop('src', img);
		 }
		 
	}

}